import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const pits1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "A gamer világban mindenki által ismert PlayIT márka keresett meg bennünket nem kissebb feladattal, mint hogy szeretnének egy webáruházat, amely az általuk használt Octopus 8 ERP-vel legyen összekötve. Ezt valósítottuk meg és folyamatason üzemeltetjük.",
  displayXo: true,
  reverse: false,
  title: "Nagy márka, nagy felelősség",
  picture: "/images/refs/pits/ref_color.png",
  boldDescription: ''
}

const pits2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "A kezdeti koordinációs időszak után kialakítottuk a megfelelő működési mechanizmust az egyes fejlesztési szakaszok zökkenőmentes végrehajtása érdekében. Nagyon elégedettek vagyunk minden probléma gyors és rugalmas megoldásával, ami elengedhetetlen alapköve a webáruháznak.",
  displayXo: true,
  reverse: true,
  title: "Webshop továbbfejlesztés",
  picture: "/images/refs/pits/pits_2.png",
  boldDescription: ''
}

export const pitsInfoCards = [
  pits1,
  pits2,
]

