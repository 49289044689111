import { Reference } from "../reference-interface"
import { pitsInfoCards } from "../../service-info-card-interface/used_by/play-it-store-cards"

export const PlayItStore : Reference = {
  monitorPic: "/images/imac_pits_template.png",
  siteName: "PlayIt Store webshop",
  siteDescription: "A játékok szakértőjének új webáruházra volt szüksége, ami komoly részletességgel, egyszerű kezelhetőséggel és átláthatósággal mutatja be az általuk forgalmazott termékeket",
  siteUrl: "https://www.playitstore.hu/",
  cards: pitsInfoCards,
  referencePics: [
    "/images/refs/pits/pits_1.jpg",
    "/images/refs/pits/ref.png",
    "/images/refs/pits/pits_3.jpg",
  ],
  pageFunctions: [
    "Octopus 8 ERP",
    "Egyedi design",
    "Önálló adminfelület",
    "Ügyfélszolgálat",
    "SEO optimalizálás" ,
  ],
  reversePageFunctions: false,
}
